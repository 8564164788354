<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="Edit Wiki"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">Edit Wiki</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div>
                    <span>Wiki Name:</span>
                    <input ref="name-input" v-model="name" maxlength="127" type="text" class="form-control"
                           placeholder="Wiki Name"
                           required/>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <div>
                    <span>Wiki Description:</span>
                    <textarea v-model="description" maxlength="1023" type="text" class="form-control"
                              placeholder="Wiki Description"></textarea>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-5">
                <vue-switch-with-label name="for_user" v-model="showContentCounters"
                                       label="Show content count"></vue-switch-with-label>
            </div>
            <div class="col-sm-7">
                Show content count of each section.
            </div>
        </div>
        <div class="row margin-15-top" v-if="allowManageTemplate">
            <div class="col-sm-5">
                <vue-switch-with-label name="for_user" v-model="isTemplate"
                                       label="Wiki Template"></vue-switch-with-label>
            </div>
            <div class="col-sm-7">
                Create Template from this Wiki. Can be applied to any wiki in your AH.
            </div>
        </div>

        <div class="row margin-15-top" v-if="isTemplate">
            <div class="col-sm-5">
                <vue-switch-with-label name="for_user" v-model="fixTemplate"
                                       label="Fix Template"></vue-switch-with-label>
            </div>
            <div class="col-sm-7">
                Prevent changes to the names and order of fixed sections in child wikis.
            </div>
        </div>

        <div class="row margin-15-top" v-if="isTemplate">
            <div class="col-sm-5">
                <vue-switch-with-label name="for_user" v-model="hasLogo"
                                       label="Add logo"></vue-switch-with-label>
            </div>
            <div class="col-sm-7">
                <img
                        v-if="hasLogo&&(logo64||logo)"
                        :src="logo64||logo"
                        height="100"
                        alt="Logo"
                >
            </div>
        </div>

        <div class="row" v-if="isTemplate">
            <div class="col-sm-12">
                <div>
                    <span>Template Name:</span>
                    <input ref="name-input" v-model="templateName" maxlength="127" type="text" class="form-control"
                           placeholder="Template Name"
                           required/>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-if="isTemplate">
            <div class="col-sm-12">
                <div>
                    <span>Template Description:</span>
                    <textarea v-model="templateDescription" maxlength="1023" type="text" class="form-control"
                              placeholder="Template Description"></textarea>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-8 text-left margin-15-top">
                    <button
                            v-if="canDeleteWiki"
                            class="btn btn-mm btn-red"
                            @click="deleteWiki"
                            style="font-size: 14px"
                    >Delete Wiki
                    </button>
                    <button
                            v-if="canMakeWikiMain"
                            class="btn btn-mm blue"
                            @click="makeMain"
                            style="font-size: 14px"
                    >Make main Wiki
                    </button>
                </div>
                <div class="col-sm-4 text-right margin-15-top">
                    <button
                            class="btn btn-mm btn-green"
                            @click="saveWiki"
                            :disabled="!allowSave"
                    >Save
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from '../../../../vue/common_components/BasicModal';
    import PopupPromiseMixin from '../../../../vue/mixins/PopupPromiseMixin';
    import Vue from 'vue';

    export default {
        name: 'WikiSettingsPopup',
        components: { BasicModal },
        mixins: [PopupPromiseMixin],
        data() {
            return {
                name: '',
                description: '',
                allowManageTemplate: false,
                showContentCounters: false,
                canMakeWikiMain: false,
                canDeleteWiki: false,
                isTemplate: false,
                fixTemplate: false,

                hasLogo: false,
                logo: undefined,
                logo64: undefined,

                initialized: false,

                templateName: '',
                templateDescription: '',
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs['name-input'];
            },
            allowSave() {
                const nameValid = this.name && (this.name.length > 3);
                if (this.isTemplate) {
                    const templateNameValid = this.templateName && (this.templateName.length > 3);
                    return templateNameValid && nameValid;
                } else {
                    return nameValid;
                }

            }
        },
        methods: {
            setInitial(
                name = '',
                description = '',
                allowManageTemplate = false,
                wikiTemplate = null,
                canMakeWikiMain = false,
                showContentCounters = false,
                canDeleteWiki = false,
            ) {
                this.name = name;
                this.description = description;
                this.allowManageTemplate = allowManageTemplate;
                this.canMakeWikiMain = canMakeWikiMain;
                this.isTemplate = !!wikiTemplate;
                this.fixTemplate = wikiTemplate?.is_fixed;
                this.showContentCounters = showContentCounters;
                this.canDeleteWiki = canDeleteWiki;
                this.templateName = wikiTemplate ? wikiTemplate.name : '';
                this.templateDescription = wikiTemplate ? wikiTemplate.description : '';
                if (wikiTemplate?.logo) {
                  this.hasLogo = true;
                  this.logo = wikiTemplate.logo;
                }
            },
            showedHook(){
              this.initialized = true;
            },
            saveWiki() {
                let templateData = null;
                const data = {
                    name: this.name,
                    description: this.description,
                    show_content_counters: this.showContentCounters,
                };
                if (this.isTemplate) {
                    templateData = {
                        name: this.templateName,
                        description: this.templateDescription,
                        is_fixed: this.fixTemplate,
                        reset_logo: !this.hasLogo,
                        logo64: this.logo64 ? this.logo64.split(',')[1] : null,
                    };
                }
                this.save({
                    action: 'save',
                    data,
                    templateData,
                });
            },
            deleteWiki() {
                this.save({
                    action: 'delete',
                });
            },
            makeMain() {
                this.save({
                    action: 'make-main',
                });
            },
            async addImage() {
                const addMedia = Vue.getAngularModule('addMedia');
                const res = await addMedia.addAvatarByCrop('Add Logo', undefined, 'Cancel', true, true, {
                    maxAspectRatio: 3,
                    minAspectRatio: 1
                }, true).catch(() => {
                });
                if (res) {
                    this.hasLogo = true;
                    this.logo64 = res;
                } else {
                    this.hasLogo = !!this.logo;
                }
            },
        },
        watch: {
            hasLogo(newValue) {
                if(!this.initialized){
                    return;
                }
                if (newValue) {
                    this.addImage();
                } else {
                    this.logo64 = undefined;
                }
            },
            isTemplate(n, o) {
                if (!o && n) {
                    if (!this.templateName && !this.templateDescription) {
                        this.templateName = this.name;
                        this.templateDescription = this.description;
                    }
                }
            }
        }
    };
</script>
