var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Edit Wiki", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v("Edit Wiki")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Wiki Name:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              ref: "name-input",
              staticClass: "form-control",
              attrs: {
                maxlength: "127",
                type: "text",
                placeholder: "Wiki Name",
                required: "",
              },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Wiki Description:")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description",
                },
              ],
              staticClass: "form-control",
              attrs: {
                maxlength: "1023",
                type: "text",
                placeholder: "Wiki Description",
              },
              domProps: { value: _vm.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.description = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-5" },
          [
            _c("vue-switch-with-label", {
              attrs: { name: "for_user", label: "Show content count" },
              model: {
                value: _vm.showContentCounters,
                callback: function ($$v) {
                  _vm.showContentCounters = $$v
                },
                expression: "showContentCounters",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-7" }, [
          _vm._v("\n            Show content count of each section.\n        "),
        ]),
      ]),
      _vm._v(" "),
      _vm.allowManageTemplate
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-5" },
              [
                _c("vue-switch-with-label", {
                  attrs: { name: "for_user", label: "Wiki Template" },
                  model: {
                    value: _vm.isTemplate,
                    callback: function ($$v) {
                      _vm.isTemplate = $$v
                    },
                    expression: "isTemplate",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _vm._v(
                "\n            Create Template from this Wiki. Can be applied to any wiki in your AH.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isTemplate
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-5" },
              [
                _c("vue-switch-with-label", {
                  attrs: { name: "for_user", label: "Fix Template" },
                  model: {
                    value: _vm.fixTemplate,
                    callback: function ($$v) {
                      _vm.fixTemplate = $$v
                    },
                    expression: "fixTemplate",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _vm._v(
                "\n            Prevent changes to the names and order of fixed sections in child wikis.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isTemplate
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-5" },
              [
                _c("vue-switch-with-label", {
                  attrs: { name: "for_user", label: "Add logo" },
                  model: {
                    value: _vm.hasLogo,
                    callback: function ($$v) {
                      _vm.hasLogo = $$v
                    },
                    expression: "hasLogo",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _vm.hasLogo && (_vm.logo64 || _vm.logo)
                ? _c("img", {
                    attrs: {
                      src: _vm.logo64 || _vm.logo,
                      height: "100",
                      alt: "Logo",
                    },
                  })
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isTemplate
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", [
                _c("span", [_vm._v("Template Name:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateName,
                      expression: "templateName",
                    },
                  ],
                  ref: "name-input",
                  staticClass: "form-control",
                  attrs: {
                    maxlength: "127",
                    type: "text",
                    placeholder: "Template Name",
                    required: "",
                  },
                  domProps: { value: _vm.templateName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.templateName = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isTemplate
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", [
                _c("span", [_vm._v("Template Description:")]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateDescription,
                      expression: "templateDescription",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    maxlength: "1023",
                    type: "text",
                    placeholder: "Template Description",
                  },
                  domProps: { value: _vm.templateDescription },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.templateDescription = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-8 text-left margin-15-top" }, [
            _vm.canDeleteWiki
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-red",
                    staticStyle: { "font-size": "14px" },
                    on: { click: _vm.deleteWiki },
                  },
                  [_vm._v("Delete Wiki\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.canMakeWikiMain
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm blue",
                    staticStyle: { "font-size": "14px" },
                    on: { click: _vm.makeMain },
                  },
                  [_vm._v("Make main Wiki\n                ")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4 text-right margin-15-top" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: !_vm.allowSave },
                on: { click: _vm.saveWiki },
              },
              [_vm._v("Save\n                ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }