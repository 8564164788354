/**
 * Created by orion on 07.03.17.
 */

import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
import angular from 'angular';
import captionPlayerBus from "../shared/captionPlayer";
import AddAvatarByCropPopup from './AddAvatarByCropPopup.vue';
import telestreamUploadPopup from './telestreamUploadPopup.vue';
import PictureFromWebcamComponent from "./PictureFromWebcamComponent.vue";
import AvatarCropPopup from "./AvatarCropPopup.vue";
import ImageUploadPopup from "./popups/ImageUploadPopup.vue";
import telestreamUploadAudioPopup from "./telestreamUploadAudioPopup";
import AddMediaPopup from "./popups/AddMediaPopup";
import AddVideoPopup from "./popups/AddVideoPopup";
import AddAudioPopup from "./popups/AddAudioPopup";
import AddPicturePopup from "./popups/AddPicturePopup";
import AddDocumentPopup from "./popups/AddDocumentPopup";
import AddFromGoogleDrivePopup from "./AddFromGoogleDrivePopup";
import AddTextPopup from "./popups/AddTextPopup";
import ImageUploadFilesSelectorPopup from "./popups/ImageUploadFilesSelectorPopup";
import AddYoutubeVideoPopup from "./popups/AddYoutubeVideoPopup.vue";
import AddWebsiteLinkPopup from "./popups/AddWebsiteLinkPopup.vue";
import DocumentUploadPopup from "./popups/DocumentUploadPopup.vue";



addMedia.$inject = ['$interval', '$log', 'addMediaResources', 'notifications', '$rootScope', 'addMediaFromFolderFactory', 'SessionHandler', 'simplePopupFactory'];

function addMedia($interval, $log, addMediaResources, notifications, $rootScope, addMediaFromFolderFactory, SessionHandler, simplePopupFactory) {

    let CALLBACKS = {};

    const ANIMATION = false;

    let _CONFIG = {};

    let DEFAULT_CONFIG = {
        anonymous_token: undefined,
        allow_add_from_library: false,
        allow_capture: true,
        allow_save_to_folders: true,
        type: 'all',
        max_count: 24,
        types: ['video', 'audio', 'image', 'document'],
        popup_header: 'Select media type you want to add.',
        allow_remove: false
    };

    let start_encode_waiting = function (media_item) { // for video and audio
        notifications.success('Saved and ready to post! please allow a few moments for your media to encode before it will play, we will notify you when it is ready.', 6000);
        let encoding_media = media_item.upl_video || media_item.recorded_audio;
        let refresh = function () {
            addMediaResources.check_encoding(encoding_media.encoding_id, media_item.type).then(function (res) {
                    media_item[media_item.type] = res.data;
                    $interval.cancel(updater);
                    notifications.success((media_item.type === 'upl_video' ? 'Video' : 'Audio') + ' ' + media_item.name + 'is now available.');
                    if (media_item.type === 'upl_video') {
                        media_item.thumbnail = res.data.preview_url; // fix for folder item
                    }
                    captionPlayerBus.$emit('mediaEncoded', {
                        type: media_item.type,
                        encoding_id: encoding_media.encoding_id,
                        encoding_media: media_item
                    });
                }
            );
        };
        if (media_item.type !== 'upl_video' && media_item.type !== 'recorded_audio' || encoding_media.encoding_done === true) {
            return undefined;
        }
        var updater = $interval(refresh, 2000, 100, true);
    };

    let telestream_upload_audio = function () {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(telestreamUploadAudioPopup);
        if (!_CONFIG.anonymous_token) {
            SessionHandler.start_session_auto_support.call(SessionHandler);
        }
        popupComponent.show({
            allowSaveToMedia: _CONFIG.allow_save_to_folders,
            maxCount: _CONFIG.max_count,
            anonymous_token: _CONFIG.anonymous_token,
        })
            .then(function (result_media_items) {
                CALLBACKS.process_telestream_upload_result(result_media_items);
                angular.forEach(result_media_items, function (ri, i) {
                    start_encode_waiting(ri);
                });
            }, function cancel(reason) {
                if (reason === 'back') {
                    add_audio_to_folder();
                } else {
                    CALLBACKS.cancel_callback(reason);
                }
            })
            .finally(() => {
                fEl?.focus();
                if (!_CONFIG.anonymous_token) {
                    SessionHandler.stop_session_auto_support.call(SessionHandler);
                }
                if (vm) {
                    vm.$destroy();
                }
            });
    };

    let telestream_upload = function () {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(telestreamUploadPopup);
        if (!_CONFIG.anonymous_token) {
            SessionHandler.start_session_auto_support.call(SessionHandler);
        }
        popupComponent.show({
            allowSaveToMedia: _CONFIG.allow_save_to_folders,
            maxCount: _CONFIG.max_count,
            anonymous_token: _CONFIG.anonymous_token,
        })
            .then(function (result_media_items) {
                CALLBACKS.process_telestream_upload_result(result_media_items);
                angular.forEach(result_media_items, function (ri, i) {
                    start_encode_waiting(ri);
                });
            }, function cancel(reason) {
                if (reason === 'back') {
                    add_video_to_folder();
                } else {
                    CALLBACKS.cancel_callback(reason);
                }
            })
            .finally(() => {
                if (!_CONFIG.anonymous_token) {
                    SessionHandler.stop_session_auto_support.call(SessionHandler);
                }
                fEl?.focus();
                if (vm) {
                    vm.$destroy();
                }
            });
    };

    let crop_webcam_photo = function (avatarBase64value) {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AvatarCropPopup);
        return popupComponent
            .show(avatarBase64value, 'Add Photo', true, _CONFIG.allow_save_to_folders)
            .then(({avatarBase64value, saveToMedia}) => {
                $rootScope.show_dimmer();
                return addMediaResources.add_picture_by_base_value(avatarBase64value.split(',')[1], saveToMedia, _CONFIG.anonymous_token)
                    .then(function (response) {
                        let result_media_item = response.data;
                        CALLBACKS.process_image_upload_result([result_media_item]);
                        $rootScope.hide_dimmer();
                    }, function (error) {
                        $rootScope.hide_dimmer();
                        notifications.error('Something went wrong. Contact us.');
                    });
            })
            .finally(() => {
                $rootScope.hide_dimmer();
                fEl?.focus();
                if (vm) {
                    vm.$destroy();
                }
            });
    };

    let take_webcam_photo = function () {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(PictureFromWebcamComponent);
        return popupComponent
            .show('Add Photo')
            .then((avatarBase64value) => {
                crop_webcam_photo(avatarBase64value);
            }, (reason) => {
                if (reason === 'back') {
                    add_picture_to_folder();
                } else {
                    CALLBACKS.cancel_callback(reason);
                }
            })
            .finally(() => {
                fEl?.focus();
                if (vm) {
                    vm.$destroy();
                }
            });
    };

    let record_video = function () {
        $rootScope.show_dimmer();
        import(/* webpackChunkName: "recorders" */ './VideoMediaRecorderPopup.vue').then(module => {
            const videoRecorderPopup = module.default;
            $rootScope.hide_dimmer();
            const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(videoRecorderPopup);
            popupComponent.show({
                allowSaveToMedia: _CONFIG.allow_save_to_folders,
                anonymous_token: _CONFIG.anonymous_token,
            })
                .then(({recordingId, saveToMedia}) => {
                    $rootScope.show_dimmer();
                    addMediaResources.add_recorded_video_by_id(recordingId, saveToMedia, _CONFIG.anonymous_token).then(
                        function (response) {
                            let result_media_item = response.data;
                            CALLBACKS.process_record_video_result([result_media_item]);
                            start_encode_waiting(result_media_item);
                            $rootScope.hide_dimmer();
                        }, function (error) {
                            $rootScope.hide_dimmer();
                            notifications.error('Something went wrong. Contact us.');
                        }
                    );
                }, reason => {
                    if (reason === 'back') {
                        add_video_to_folder();
                    } else {
                        CALLBACKS.cancel_callback(reason);
                    }
                })
                .finally(() => {
                    fEl?.focus();
                    if (vm) {
                        vm.$destroy();
                    }
                });
        });
    };

    let record_audio = function (closeOnBack = undefined) {
        $rootScope.show_dimmer();
        import(/* webpackChunkName: "recorders" */ './audioRecorderPopup.vue').then(module => {
            const audioRecorderPopup = module.default;
            $rootScope.hide_dimmer();
            const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(audioRecorderPopup);
            popupComponent.show({
                allowSaveToMedia: _CONFIG.allow_save_to_folders,
                anonymous_token: _CONFIG.anonymous_token,
            })
                .then(({recordingId, saveToMedia}) => {
                    $rootScope.show_dimmer();
                    addMediaResources.add_recorded_audio_by_id(recordingId, saveToMedia, _CONFIG.anonymous_token).then(
                        function (response) {
                            let result_media_item = response.data;
                            CALLBACKS.process_record_audio_result([result_media_item]);
                            start_encode_waiting(result_media_item);
                            $rootScope.hide_dimmer();
                        }, function (error) {
                            $rootScope.hide_dimmer();
                            notifications.error('Something went wrong. Contact us.');
                        }
                    );
                }, reason => {
                    if (closeOnBack) {
                        CALLBACKS.cancel_callback(reason);
                        return;
                    }
                    if (reason === 'back') {
                        add_audio_to_folder();
                    } else {
                        CALLBACKS.cancel_callback(reason);
                    }
                })
                .finally(() => {
                    fEl?.focus();
                    if (vm) {
                        vm.$destroy();
                    }
                });
        });

    };

    let add_from_folder = function (filter) {
        addMediaFromFolderFactory.add_media(CALLBACKS.process_add_from_folder_result, filter, _CONFIG.max_count, CALLBACKS.cancel_callback);
    };

    let uploadRecordedVideoFile = (file) => {
        $rootScope.show_dimmer();
        import(/* webpackChunkName: "recorders" */ './VideoMediaRecorderPopup.vue').then(module => {
            const videoRecorderPopup = module.default;
            $rootScope.hide_dimmer();
            const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(videoRecorderPopup);
            popupComponent.show({
                allowSaveToMedia: _CONFIG.allow_save_to_folders,
                anonymous_token: _CONFIG.anonymous_token
            }, file)
                .then(({recordingId, saveToLibrary}) => {
                    $rootScope.show_dimmer();
                    addMediaResources.add_recorded_video_by_id(recordingId, saveToLibrary, _CONFIG.anonymous_token).then(
                        function (response) {
                            let result_media_item = response.data;
                            CALLBACKS.process_record_video_result([result_media_item]);
                            start_encode_waiting(result_media_item);
                            $rootScope.hide_dimmer();
                        }, function (error) {
                            $rootScope.hide_dimmer();
                            notifications.error('Something went wrong. Contact us.');
                        }
                    );
                })
                .finally(() => {
                    fEl?.focus();
                    vm?.$destroy();
                });
        });
    };

    let add_video_to_folder = function () { // may be not to folder
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddVideoPopup);
        popupComponent.show(_CONFIG)
            .then(result => {
                if (result.action === 'select') {
                    const selection = result.selection;
                    if (selection === 'record') {
                        record_video();
                    }
                    if (selection === 'device') {
                        telestream_upload();
                    }
                    if (selection === 'youtube') {
                        add_video_from_youtube();
                    }
                    if (selection === 'folder') {
                        add_from_folder('video');
                    }
                } else if (result.action === 'file') {
                    uploadRecordedVideoFile(result.file);
                }
            }, reason => {
                if (reason === 'back' && _CONFIG.type === 'all') {
                    add_media_to_somewhere();
                } else {
                    CALLBACKS.cancel_callback(reason);
                }
            })
            .finally(() => {
                fEl?.focus();
                vm?.$destroy();
            });
    };

    let image_upload_direct = function () {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(ImageUploadFilesSelectorPopup);
        return popupComponent
            .show(_CONFIG)
            .then(data => {
                image_upload(true, data.files);
            }, reason => {
                CALLBACKS.cancel_callback(reason);
            })
            .finally(() => {
                fEl?.focus();
                if (vm) {
                    vm.$destroy();
                }
            });
    };

    let image_upload = function (closeOnBack = undefined, initialFiles = undefined) {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(ImageUploadPopup);
        return popupComponent
            .show(initialFiles, _CONFIG.allow_save_to_folders, {
                anonymous_token: _CONFIG.anonymous_token,
            })
            .then(createdMediaFiles => {
                CALLBACKS.process_image_upload_result(createdMediaFiles);
            }, reason => {
                if (reason === 'back') {
                    if (closeOnBack) {
                        CALLBACKS.cancel_callback(reason);
                    } else {
                        add_picture_to_folder();
                    }
                } else {
                    CALLBACKS.cancel_callback(reason);
                }

            })
            .finally(() => {
                fEl?.focus();
                if (vm) {
                    vm.$destroy();
                }
            });
    };

    let document_upload = function (initialFiles) {

        const {fEl, popupComponent, vm} = simplePopupFactory.mount_vue_popup(DocumentUploadPopup);
        return popupComponent
            .show({
            initialFiles,
            url: DjangoUrls['api.v1:media_upload']('document'),
            max_count: _CONFIG.max_count,
            anonymous_token: _CONFIG.anonymous_token,
            allow_save_to_folders: _CONFIG.allow_save_to_folders
            })

           .then(CALLBACKS.process_document_upload_result, reason => {
                if (reason === 'back') {
                    if (_CONFIG.allow_add_from_library) {
                        document_upload_menu();
                    } else {
                        add_media_to_somewhere();
                    }
                } else {
                CALLBACKS.cancel_callback(reason);
                }
           })
           .finally(() => {
               vm?.$destroy();
               fEl?.focus();
           })
    };

    let add_from_gdrive = function () {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddFromGoogleDrivePopup);
        popupComponent.show(_CONFIG)
            .then(res => {
                    addMediaResources.add_upl_file_from_gdrive(res).then(function (resp) {
                        CALLBACKS.process_document_upload_result(resp.data);
                        $rootScope.hide_dimmer();
                    }, function (resp) {
                        notifications.error(resp.data);
                    });
                },
                reason => {
                    if (reason === 'back') {
                        document_upload_menu();
                    } else {
                        CALLBACKS.cancel_callback(reason);
                    }
                })
            .finally(() => {
                fEl?.focus();
                vm?.$destroy();
            });
    };

    function uploadRecordedAudioFile(file) {
        $rootScope.show_dimmer();
        import(/* webpackChunkName: "recorders" */ './audioRecorderPopup.vue').then(module => {
            const audioRecorderPopup = module.default;
            $rootScope.hide_dimmer();
            const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(audioRecorderPopup);
            popupComponent.show({
                allowSaveToMedia: _CONFIG.allow_save_to_folders,
                anonymous_token: _CONFIG.anonymous_token
            }, file)
                .then(({recordingId, saveToLibrary}) => {
                    $rootScope.show_dimmer();
                    addMediaResources.add_recorded_audio_by_id(recordingId, saveToLibrary, _CONFIG.anonymous_token).then(
                        function (response) {
                            let result_media_item = response.data;
                            CALLBACKS.process_record_audio_result([result_media_item]);
                            start_encode_waiting(result_media_item);
                            $rootScope.hide_dimmer();
                        }, function (error) {
                            $rootScope.hide_dimmer();
                            notifications.error('Something went wrong. Contact us.');
                        }
                    );
                })
                .finally(() => {
                    fEl?.focus();
                    vm?.$destroy();
                });
        });
    }

    let add_audio_to_folder = function () {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddAudioPopup);
        popupComponent.show(_CONFIG)
            .then(result => {
                if (result.action === 'select') {
                    const selection = result.selection;
                    if (selection === 'device') {
                        telestream_upload_audio();
                    }
                    if (selection === 'folder') {
                        add_from_folder('audio');
                    }
                    if (selection === 'record') {
                        record_audio();
                    }
                } else if (result.action === 'file') {
                    uploadRecordedAudioFile(result.file);
                }
            }, reason => {
                if (reason === 'back' && _CONFIG.type === 'all') {
                    add_media_to_somewhere();
                } else {
                    CALLBACKS.cancel_callback(reason);
                }
            })
            .finally(() => {
                fEl?.focus();
                vm?.$destroy();
            });
    };


    let add_video_from_youtube = function () { // may be not to folder
        const {fEl, popupComponent, vm} = simplePopupFactory.mount_vue_popup(AddYoutubeVideoPopup);
        popupComponent.show({
            max_count: _CONFIG.max_count,
            allow_save_to_folders: _CONFIG.allow_save_to_folders
        })
        .then(function s(res) {
            $rootScope.show_dimmer();
            addMediaResources.add_youtube_videos(res.links, res.save_to_library, _CONFIG.anonymous_token).then(function (resp) {
                CALLBACKS.process_youtube_links_add(resp.data);
                $rootScope.hide_dimmer();
            }, function (err) {
                $rootScope.hide_dimmer();
                notifications.error(err.data.links.join() || 'Something went wrong.');
            });
        }, function e(reason) {
            if (reason === 'back') {
                add_video_to_folder();
            } else {
                CALLBACKS.cancel_callback(reason);
            }
        })
        .finally(() => {
            fEl?.focus();
            vm?.$destroy();
        });
    };


    let add_picture_to_folder = function () { // may be not to folder
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddPicturePopup);
        popupComponent.show(_CONFIG)
            .then(result => {
                if (result.action === 'select') {
                    const selection = result.selection;
                    if (selection === 'capture') {
                        take_webcam_photo();
                    }
                    if (selection === 'device') {
                        image_upload();
                    }
                    if (selection === 'folder') {
                        add_from_folder('image');
                    }
                } else if (result.action === 'file') {
                    $rootScope.show_dimmer();
                    addMediaResources.create_media_content_item_from_file(result.file, _CONFIG.anonymous_token).then(function (resp) {
                        $rootScope.hide_dimmer();
                        CALLBACKS.process_image_upload_result([resp.data]);
                    }, function () {
                        CALLBACKS.cancel_callback('error');
                        notifications.error('Image error');
                        $rootScope.hide_dimmer();
                    });
                } else if (result.action === 'files') {
                    image_upload(undefined, result.files);
                }
            }, reason => {
                if (reason === 'back' && _CONFIG.type === 'all') {
                    add_media_to_somewhere();
                } else {
                    CALLBACKS.cancel_callback(reason);
                }
            })
            .finally(() => {
                fEl?.focus();
                vm?.$destroy();
            });
    };

    let document_upload_menu = function () {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddDocumentPopup);
        popupComponent.show(_CONFIG)
            .then(result => {
                if (result.action === 'select') {
                    const selection = result.selection;
                    if (selection === 'folder') {
                        add_from_folder('document');
                    }
                    if (selection === 'gdrive') {
                        add_from_gdrive('document');
                    }
                } else if (result.action === 'files') {
                    document_upload(result.files);
                }

            }, reason => {
                if (reason === 'back' && _CONFIG.type === 'all') {
                    add_media_to_somewhere();
                } else {
                    CALLBACKS.cancel_callback(reason);
                }
            })
            .finally(() => {
                fEl?.focus();
                vm?.$destroy();
            });
    };

    let add_link_menu = function () {
        const {fEl, popupComponent, vm} = simplePopupFactory.mount_vue_popup(AddWebsiteLinkPopup);
        popupComponent.show()

        .then(function s(res) {
            $rootScope.show_dimmer();
            addMediaResources.add_link(res)
                .then(function (resp) {
                    $rootScope.hide_dimmer();
                    CALLBACKS.process_add_link_result([resp.data]);
                }, function (err) {
                    $rootScope.hide_dimmer();
                    notifications.error(err || 'Something went wrong.');
                });
        }, function e(reason) {
            if (reason === 'back') {
                add_media_to_somewhere();
            }
        })
        .finally(() => {
            fEl?.focus();
            vm?.$destroy();
        });

    };

    let add_media_to_somewhere = function () {
        if (_CONFIG.type === 'video') {
            add_video_to_folder();
            return;
        }
        if (_CONFIG.type === 'audio') {
            add_audio_to_folder();
            return;
        }
        if (_CONFIG.type === 'image') {
            add_picture_to_folder();
            return;
        }
        if (_CONFIG.type === 'document') {
            document_upload_menu();
            return;
        }
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddMediaPopup);
        popupComponent.show(_CONFIG)
            .then(({selection}) => {
                if (selection === 'video') {
                    add_video_to_folder();
                }
                if (selection === 'audio') {
                    add_audio_to_folder();
                }
                if (selection === 'picture') {
                    add_picture_to_folder();
                }
                if (selection === 'document') {
                    document_upload_menu();
                }
                if (selection === 'link') {
                    add_link_menu();
                }
            }, reason => {
                CALLBACKS.cancel_callback(reason);
            })
            .finally(() => {
                fEl?.focus();
                vm?.$destroy();
            });
    };

    function add_text(initial, allow_header = true, allow_blank = false, label = '') {
        /**
         * @return Object {text: string, is_header: boolean}
         */

        return new Promise((resolve, reject) => {
            const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddTextPopup);
            popupComponent.show(initial, allow_header, allow_blank, label)
                .then(data => {
                    resolve(data);
                }, () => {
                    reject('close');
                })
                .finally(() => {
                    fEl?.focus();
                    vm?.$destroy();
                });
        });
    }

    function addAvatarByCrop(...rest) {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddAvatarByCropPopup);
        return popupComponent
            .show(...rest)
            .finally(() => {
                fEl?.focus();
                if (vm) {
                    vm.$destroy();
                }
            });
    }

    function addImagesByDragDrop(files) {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(ImageUploadPopup);
        return popupComponent
            .show(files, _CONFIG.allow_save_to_folders)
            .finally(() => {
                fEl?.focus();
                if (vm) {
                    vm.$destroy();
                }
            });
    }

    return {
        add_media: function (config_object, callback_object, start_function = undefined) {
            CALLBACKS.process_image_upload_result = callback_object.process_image_upload_result || callback_object.default_callback;
            CALLBACKS.process_telestream_upload_result = callback_object.process_telestream_upload_result || callback_object.default_callback;
            CALLBACKS.process_youtube_links_add = callback_object.process_youtube_links_add || callback_object.default_callback;
            CALLBACKS.process_record_video_result = callback_object.process_record_video_result || callback_object.default_callback;
            CALLBACKS.process_record_audio_result = callback_object.process_record_audio_result || callback_object.default_callback;
            CALLBACKS.process_audio_upload_result = callback_object.process_audio_upload_result || callback_object.default_callback;
            CALLBACKS.process_document_upload_result = callback_object.process_document_upload_result || callback_object.default_callback;
            CALLBACKS.process_add_link_result = callback_object.process_add_link_result || callback_object.default_callback;
            CALLBACKS.process_capture_photo_result = callback_object.process_capture_photo_result || callback_object.default_callback;
            CALLBACKS.process_add_from_folder_result = callback_object.process_add_from_folder_result || callback_object.default_callback;
            CALLBACKS.cancel_callback = callback_object.cancel_callback || angular.noop;
            angular.extend(_CONFIG, DEFAULT_CONFIG, config_object);
            if (!start_function) {
                add_media_to_somewhere();
            } else {
                if (start_function === 'image_upload') {
                    image_upload_direct();
                } else if (start_function === 'record_audio') {
                    record_audio(true);
                }
            }
        },
        add_text: add_text,
        addAvatarByCrop,
        addImagesByDragDrop: (...rest) => {
            Object.assign(_CONFIG, DEFAULT_CONFIG);
            return addImagesByDragDrop(...rest);
        },
    };
}

export default addMedia;
