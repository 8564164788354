/**
 * Created by orion on 6/24/17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';

let {DjangoUrls} = urls;
CalendarEventGoalsResource.$inject = ['$http'];
const PREFIX = 'api.v1:calendar_event_goals-';

function CalendarEventGoalsResource($http) {
    return {
        get_goals_owners_for_filter,
        get_linked_goals,
        link_goal,
        add_task_to_link,
        unlink_goal,
        get_users_goals_for_link,
        load_tasks_from_goal,

        get_users_goals_for_link_diary_entry,
        get_users_tasks_for_link_in_goal,
        get_tasks_for_link_goal,
        get_linked_tasks_for_edit,
    };


    function get_goals_owners_for_filter(calendarOwnerId, eventId) {
        const url = DjangoUrls[PREFIX + "get-goals-owners-for-filter"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                id: calendarOwnerId,
            }
        });
    }

    function link_goal(calendarOwnerId, eventId, goalId, tasksIdList, allTasks) {
        const url = DjangoUrls[PREFIX + "link-goal"](eventId);
        return $http({
            method: 'POST',
            url: url,
            data: {
                calendar_owner_id: calendarOwnerId,
                goal_id: goalId,
                id_list: tasksIdList,
                all: allTasks
            }
        });
    }

    function add_task_to_link(calendarOwnerId, eventId, goalId, tasksId) {
        const url = DjangoUrls[PREFIX + "add-task-to-link"](eventId);
        return $http({
            method: 'POST',
            url: url,
            data: {
                calendar_owner_id: calendarOwnerId,
                goal_id: goalId,
                id: tasksId,
            }
        });
    }

    function unlink_goal(calendarOwnerId, eventId, goalId) {
        const url = DjangoUrls[PREFIX + "unlink-goal"](eventId);
        return $http({
            method: 'POST',
            url: url,
            data: {calendar_owner_id: calendarOwnerId, goal_id: goalId}
        });
    }

    function get_users_goals_for_link(calendarOwnerId, eventId, params) {
        const url = DjangoUrls[PREFIX + "get-users-goals-for-link"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: _.merge({id: calendarOwnerId}, params)
        });
    }

    function get_linked_goals(calendarOwnerId, eventId, eventDate) {
        const url = DjangoUrls[PREFIX + "get-linked-goals"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {id: calendarOwnerId, event_date: eventDate}
        });
    }

    function load_tasks_from_goal(calendarOwnerId, eventId, eventDate, goalId, show_completed = false, limit, offset) {
        const url = DjangoUrls[PREFIX + "load-tasks-from-goal"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                calendar_owner_id: calendarOwnerId,
                goal_id: goalId,
                event_date: eventDate,
                limit,
                offset,
                show_completed
            }
        });
    }

    function get_users_goals_for_link_diary_entry(eventId, calendarOwnerId, profilesToCreateProxyEntries = [], params) {
        const url = DjangoUrls[PREFIX + "get-users-goals-for-link-diary-entry"](eventId);
        return $http({
            method: 'GET',
            url,
            params: _.merge({
                calendar_owner_id: calendarOwnerId,
                id_list: profilesToCreateProxyEntries,
            }, params)
        });
    }

    function get_users_tasks_for_link_in_goal(eventId, calendarOwnerId, goalId, linkedTasksIdList = [], params) {
        const url = DjangoUrls[PREFIX + "get-users-tasks-for-link-in-goal"](eventId);
        return $http({
            method: 'GET',
            url,
            params: _.merge({calendar_owner_id: calendarOwnerId, id: goalId, id_list: linkedTasksIdList}, params)
        });
    }

    function get_tasks_for_link_goal(eventId, calendarOwnerId, goalId, params) {
        const url = DjangoUrls[PREFIX + "get-tasks-for-link-goal"](eventId);
        return $http({
            method: 'GET',
            url,
            params: _.merge(
                {
                    calendar_owner_id: calendarOwnerId,
                    goal_id: goalId
                },
                params)
        });
    }

    function get_linked_tasks_for_edit(eventId, calendarOwnerId, goalId, params) {
        const url = DjangoUrls[PREFIX + "get-linked-tasks-for-edit"](eventId);
        return $http({
            method: 'GET',
            url,
            params: _.merge(
                {
                    calendar_owner_id: calendarOwnerId,
                    goal_id: goalId
                },
                params)
        });
    }

}

export default CalendarEventGoalsResource;