<template>
    <div class="fgi">
        <i v-if="editable" class="far fa-trash fgi__delete-button" tabindex="0" role="button" aria-label="delete item"
           @click="removeItem" @keydown.enter="removeItem"></i>
        <vue-inline-media v-if="post.media_attachment" :item="post.media_attachment"></vue-inline-media>
        <div v-else class="fgi__text">
            {{ post.text }}
        </div>
    </div>

</template>

<script>

    import VueInlineMedia from '~/angular/app/view_media/vue_inline_media_render_directive/VueInlineMedia.vue';

    export default {
        name: 'FillingGuidanceDialogItem',
        components: { VueInlineMedia },
        props: {
            post: Object,
            editable: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['remove'],
        methods: {
            removeItem() {
                this.$emit('remove');
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";
  @import "../../styles/mixins";

  .fgi {
    @include wide_item_container(60px);
    position: relative;

    &__text {
      font-size: $bigger-font-size;
      padding: 15px;
    }

    &__delete-button {
      position: absolute;
      color: $footer-red;
      cursor: pointer;
      top: 10px;
      right: 5px;
      font-size: 25px;
      line-height: 25px;
      display: block;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      z-index: 5;
    }
  }
</style>
