var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          !_vm.links.length
            ? _c(
                "div",
                {
                  staticClass:
                    "alert alert-info margin-15-top margin-15-bottom",
                },
                [
                  _vm._v(
                    "\n                There are no Goals linked to this Diary Entry yet.\n            "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.addToSuDiaries && _vm.groupedByCalendarOwnerLinks.length > 1
            ? _c(
                "div",
                {
                  staticClass:
                    "alert alert-info margin-15-top margin-15-bottom",
                },
                [
                  _vm._v(
                    "\n                Supported Users' Goal " +
                      _vm._s(_vm._f("customTerm")("Tasks")) +
                      " linked to this Event will automatically be linked to this Diary entry.\n            "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.groupedByCalendarOwnerLinks, function (linkGroup) {
            return _c(
              "div",
              { key: linkGroup.calendar_owner.id },
              [
                _vm.groupedByCalendarOwnerLinks.length > 1 ||
                (_vm.groupedByCalendarOwnerLinks.length === 1 &&
                  _vm.groupedByCalendarOwnerLinks[0].calendar_owner.id !==
                    _vm.calendarOwner.id)
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("img", {
                          staticClass: "img-circle vertical-middle",
                          attrs: {
                            src: linkGroup.calendar_owner.avatar,
                            alt: "",
                            width: "40",
                            height: "40",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "strong",
                          { staticClass: "vertical-middle" },
                          [
                            _c("simple-link", {
                              attrs: { target: linkGroup.calendar_owner },
                            }),
                          ],
                          1
                        ),
                        _vm._v(":\n                    "),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(linkGroup.links, function (link, i) {
                  return _c(
                    "div",
                    {
                      key: link.id,
                      staticClass: "row",
                      staticStyle: {
                        "margin-bottom": "5px",
                        "padding-top": "5px",
                      },
                      style: {
                        "border-top": i === 0 ? "none" : "solid 1px lightgrey",
                        "padding-top": i === 0 ? 0 : "5px",
                      },
                    },
                    [
                      _c("div", { staticClass: "col-sm-3 text-center" }, [
                        _c(
                          "a",
                          { attrs: { href: link.goal.link, target: "_blank" } },
                          [
                            _c("img", {
                              staticClass: "img-circle border-goal",
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { src: link.goal.avatar },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-9 no-left-padding-desktop" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-xs-9 no-left-padding-desktop",
                              },
                              [
                                _c(
                                  "strong",
                                  [
                                    _c("simple-link", {
                                      attrs: {
                                        target: link.goal,
                                        "new-tab": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("br"),
                                _vm._v(" "),
                                link.goal.description
                                  ? _c("span", [
                                      _c("small", [
                                        _c("i", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("cutName")(
                                                link.goal.description,
                                                255
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("br"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: link.tasks.length,
                                        expression: "link.tasks.length",
                                      },
                                    ],
                                  },
                                  [
                                    link.tasks.length === 1
                                      ? _c("span", [
                                          _vm._v(
                                            "1 Linked " +
                                              _vm._s(
                                                _vm._f("customTerm")("Task")
                                              ) +
                                              ":"
                                          ),
                                        ])
                                      : link.tasks.length > 1
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(link.tasks.length) +
                                              " Linked " +
                                              _vm._s(
                                                _vm._f("customTerm")("Tasks")
                                              ) +
                                              ":"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(link.tasks, function (task, j) {
                                      return _c("strong", { key: task.id }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("cutName")(task.name, 35)
                                          ) +
                                            "\n                                        "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.taskIsDeletable(task),
                                                expression:
                                                  "taskIsDeletable(task)",
                                              },
                                            ],
                                            staticClass: "color-red pointer",
                                            attrs: { href: "javascript:;" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeTaskFromGoal(
                                                  link,
                                                  task
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-trash-o remove-task-trash-icon",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  j !== link.tasks.length - 1,
                                                expression:
                                                  "j!==(link.tasks.length-1)",
                                              },
                                            ],
                                          },
                                          [_vm._v(", ")]
                                        ),
                                      ])
                                    }),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("strong", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addTaskToGoal(link.goal)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "+ Add\n                                    " +
                                          _vm._s(_vm._f("customTerm")("Task"))
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-xs-3 text-right" },
                              [
                                _c("vue-folder-button", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.goalIsDeletable(link.goal),
                                      expression: "goalIsDeletable(link.goal)",
                                    },
                                  ],
                                  attrs: {
                                    color: "red",
                                    text: "Delete",
                                    "icon-class": "fa-trash",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.deleteLinkedGoal(link)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 text-right" }, [
        _c(
          "a",
          {
            staticClass: "link-to-goal-task-label",
            attrs: { href: "javascript:;" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.linkGoal.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "+ Link to " +
                _vm._s(_vm._f("customTerm")("Goal")) +
                "\n                " +
                _vm._s(_vm._f("customTerm")("Task"))
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }